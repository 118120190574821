import { ComponentType, lazy } from "react"
import { captureMessage } from "@sentry/react"

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>

function retry(fn: () => ComponentPromise): ComponentPromise {
  return new Promise((resolve) => {
    const is404Page = window.location.pathname.includes("/404")
    fn()
      .then(resolve)
      .then(() => {
        window.localStorage.setItem("haveRefreshedBeforeOnError", "")
      })
      .catch((error) => {
        try {
          const haveRefreshedBeforeOnError = JSON.parse(window.localStorage.getItem("haveRefreshedBeforeOnError") || "false")
          console.log("error", error)
          if (!haveRefreshedBeforeOnError && !is404Page) {
            window.localStorage.setItem("haveRefreshedBeforeOnError", "true")
            console.log("chunk load error RELOADING")
            captureMessage("Chunk load error", { extra: { error } })
            return window.location.reload()
          }
        } catch (e) {
          return console.log("Storage error")
        }
      })
  })
}

export default function lazyRetryImport(component: () => ComponentPromise): React.LazyExoticComponent<React.ComponentType<any>> {
  return lazy(() => retry(component))
}
